import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="black"
          fill="black"
          strokeWidth="0"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 10 5 10 5 80 39 80 72 80 72 10"
        />
        <path
          d="M54.078,35.559c0,2.437-1.192,3.655-3.576,3.655c-2.332,0-3.496-1.112-3.496-3.337c0-0.582,0.264-1.456,0.795-2.622c0.159-0.37,0.238-0.741,0.238-1.112c0-1.059-0.544-1.933-1.629-2.622c-1.086-0.688-2.451-1.033-4.092-1.033c-3.178,0-5.642,1.378-7.39,4.132c-1.748,2.755-2.622,6.622-2.622,11.601c0,5.615,0.9,9.946,2.702,12.991c1.8,3.047,4.343,4.569,7.628,4.569c2.225,0,4.211-0.688,5.959-2.066c0.795-0.636,1.827-1.854,3.099-3.655c0.264-0.264,0.503-0.397,0.715-0.397c0.529,0,0.795,0.212,0.795,0.636c0,0.689-0.927,1.987-2.781,3.894c-2.384,2.49-5.43,3.735-9.138,3.735c-4.662,0-8.516-1.669-11.561-5.006c-3.047-3.337-4.569-7.549-4.569-12.634c0-5.455,1.615-9.972,4.847-13.548c3.23-3.576,7.283-5.363,12.157-5.363c3.125,0,5.892,0.822,8.303,2.463C52.873,31.48,54.078,33.387,54.078,35.559z"
          fill="white"
          strokeWidth="1"
          stroke="white"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
